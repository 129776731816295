import React, { Suspense, lazy } from "react";
import { Icon } from "@mui/material";
import dentist32 from "assets/images/dentist32.png";
import bubble32 from "assets/images/chart-bubble-32.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PrestadoresBurbujasChart from "examples/Charts/PrestadoresBurbujasChart";

// Lazy load components
const Dashboard = lazy(() => import("layouts/dashboard"));
const Tables = lazy(() => import("layouts/tables"));
const TablesClientes = lazy(() => import("layouts/tables/TablesClientes"));
const Billing = lazy(() => import("layouts/billing"));
const Cotizador = lazy(() => import("layouts/Cotizador/Cotizador"));
const Notifications = lazy(() => import("layouts/notifications"));
const SignIn = lazy(() => import("layouts/authentication/sign-in"));
const HeatMapArgentina = lazy(() => import("components/Maps/HeatMapArgentina"));

// Define routes
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <i class="fa-solid fa-chart-line fa-sm"></i>,
    route: "/dashboard",
    component: <Dashboard />, // Lazy loaded
  },
  {
    type: "collapse",
    name: "Prestadores",
    key: "tables",
    icon: <img src={dentist32} alt="dashboard" style={{ width: "20px", height: "20px" }} />,
    route: "/tables",
    component: <Tables />, // Lazy loaded
  },
  {
    type: "collapse",
    name: "Clientes",
    key: "tablesclientes",
    icon: <i class="fa-solid fa-id-card-clip fa-sm"></i>,
    route: "/tablesclientes",
    component: <TablesClientes />, // Lazy loaded
  },
  {
    type: "collapse",
    name: "Mapa",
    key: "mapas",
    icon: <i class="fa-solid fa-location-dot fa-sm"></i>,
    route: "/mapaargentina",
    component: <HeatMapArgentina />, // Lazy loaded
  },
  {
    type: "collapse",
    name: "Cotizador",
    key: "cotizador",
    icon: <i class="fa-solid fa-tag fa-sm"></i>,
    route: "/cotizador",
    component: <Cotizador />, // Lazy loaded
  },
  {
    type: "collapse",
    name: "Burbujas prestadores",
    key: "burbujasPrestadores",
    icon: <img src={bubble32} alt="burbujas" style={{ width: "20px", height: "20px" }} />,
    route: "/burbujasprestadores",
    component: <PrestadoresBurbujasChart />, // Lazy loaded
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />, // Lazy loaded
  },

  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />, // Lazy loaded
  },
  {
    type: "collapse",
    name: "Cerrar sesión",
    key: "sign-in",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-in/basic",
    component: <SignIn />, // Lazy loaded
  },
];

export default routes;
