import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Crypto from "components/Crypto/Crypto";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Start with loading = true
  const [showDashboard, setShowDashboard] = useState(false);
  const [monthData, setMonthData] = useState([]);
  const [anualData, setAnualData] = useState([]);
  const [totalMesActual, setTotalMesActual] = useState([]);
  const [especialidadData, setEspecialidadData] = useState([]);
  const [odontologoData, setOdontologoData] = useState([]);
  const [redData, setRedData] = useState([]);
  const [provinciasData, setProvinciasData] = useState([]);
  const [burbujasData, setBurbujasData] = useState([]);
  const [clienteData, setClienteData] = useState([]);
  const [grupoClienteData, setGrupoClienteData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [cotizarData, setCotizarData] = useState([]);
  const [calculatedData, setCalculatedData] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [chartDataLineHonorarios, setChartDataLineHonorarios] = useState([]);
  const [chartDataLinePrestaciones, setChartDataLinePrestaciones] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [zonales, setZonales] = useState(false);
  const [interior, setInterior] = useState(false);

  useEffect(() => {
    const checkAuth = () => {
      const authToken = Crypto.decryptLocalStorage("jwtcr"); // Verificar si existe el token
      if (authToken) {
        setIsAuthenticated(true); // Usuario autenticado
      } else {
        setIsAuthenticated(false); // No autenticado
      }
    };

    const restoreState = () => {
      const savedState = localStorage.getItem("appContext"); // Restaurar el estado desde localStorage
      if (savedState) {
        const parsedState = JSON.parse(savedState);

        // Restaurar todos los valores persistidos
        setShowDashboard(parsedState.showDashboard || false);
        setMonthData(parsedState.monthData || []);
        setEspecialidadData(parsedState.especialidadData || []);
        setOdontologoData(parsedState.odontologoData || []);
        setRedData(parsedState.redData || []);
        setProvinciasData(parsedState.provinciasData || []);
        setBurbujasData(parsedState.burbujasData || []);
        setClienteData(parsedState.clienteData || []);
        setGrupoClienteData(parsedState.grupoClienteData || []);
        setMapData(parsedState.mapData || []);
        setCotizarData(parsedState.cotizarData || []);
        setAnualData(parsedState.anualData || []);
        setTotalMesActual(parsedState.totalMesActual || []);
        setCalculatedData(parsedState.calculatedData || null);
        setFilterOptions(parsedState.filterOptions || []);
        setSelectedFilter(parsedState.selectedFilter || null);
        setChartDataLineHonorarios(parsedState.chartDataLineHonorarios || []);
        setChartDataLinePrestaciones(parsedState.chartDataLinePrestaciones || []);
        setSelectedDate(new Date(parsedState.selectedDate) || new Date());
        setZonales(parsedState.zonales || false);
        setInterior(parsedState.interior || false);
      }
    };

    // Ejecutar las funciones para verificar autenticación y restaurar el estado
    checkAuth();
    restoreState();

    // Establecer loading en false una vez finalizadas las verificaciones
    setLoading(false);
  }, []); // Solo se ejecuta cuando el componente se monta

  // useEffect para persistir el estado en localStorage cuando cambie
  useEffect(() => {
    const stateToPersist = {
      isAuthenticated,
      showDashboard,
      monthData,
      especialidadData,
      odontologoData,
      redData,
      provinciasData,
      burbujasData,
      clienteData,
      grupoClienteData,
      mapData,
      cotizarData,
      anualData,
      totalMesActual,
      calculatedData,
      chartDataLineHonorarios,
      chartDataLinePrestaciones,
      selectedDate,
      zonales,
      interior,
      filterOptions,
      selectedFilter,
    };
    localStorage.setItem("appContext", JSON.stringify(stateToPersist));
  }, [
    isAuthenticated,
    showDashboard,
    monthData,
    especialidadData,
    odontologoData,
    redData,
    provinciasData,
    burbujasData,
    clienteData,
    grupoClienteData,
    mapData,
    cotizarData,
    anualData,
    totalMesActual,
    calculatedData,
    chartDataLineHonorarios,
    chartDataLinePrestaciones,
    selectedDate,
    zonales,
    interior,
    filterOptions,
    selectedFilter,
  ]); // Se ejecuta cada vez que estos estados cambian

  // Function to recheck authentication
  const recheckAuth = () => {
    const authToken = Crypto.decryptLocalStorage("jwtcr");
    if (authToken) {
      setIsAuthenticated(true); // Authenticated if token exists
    } else {
      setIsAuthenticated(false); // Not authenticated if no token
    }
  };

  const resetState = () => {
    setIsAuthenticated(false);
    setShowDashboard(false);
    setMonthData([]);
    setEspecialidadData([]);
    setOdontologoData([]);
    setRedData([]);
    setProvinciasData([]);
    setBurbujasData([]);
    setClienteData([]);
    setGrupoClienteData([]);
    setMapData([]);
    setCotizarData([]);
    setAnualData([]);
    setTotalMesActual([]);
    setCalculatedData(null);
    setChartDataLineHonorarios([]);
    setChartDataLinePrestaciones([]);
    setSelectedDate(new Date()); // O la fecha que prefieras
    setZonales(false);
    setInterior(false);
    setFilterOptions([]);
    setSelectedFilter(null);

    // También puedes limpiar cualquier token en el localStorage o sessionStorage si es necesario
    localStorage.removeItem("jwtcr");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loading,
        recheckAuth,
        showDashboard,
        setShowDashboard,
        monthData,
        setMonthData,
        especialidadData,
        setEspecialidadData,
        odontologoData,
        setOdontologoData,
        redData,
        setRedData,
        provinciasData,
        setProvinciasData,
        burbujasData,
        setBurbujasData,
        clienteData,
        setClienteData,
        grupoClienteData,
        setGrupoClienteData,
        mapData,
        setMapData,
        cotizarData,
        setCotizarData,
        anualData,
        setAnualData,
        totalMesActual,
        setTotalMesActual,
        calculatedData,
        setCalculatedData,
        chartDataLineHonorarios,
        setChartDataLineHonorarios,
        chartDataLinePrestaciones,
        setChartDataLinePrestaciones,
        selectedDate,
        setSelectedDate,
        zonales,
        setZonales,
        interior,
        setInterior,
        resetState,
        filterOptions,
        setFilterOptions,
        selectedFilter,
        setSelectedFilter,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useGlobalContext() {
  return useContext(AuthContext);
}
