import React from "react";
import GenericBurbujasChart from "./GenericBurbujasChart";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Card } from "@mui/material";

// Este componente recibe los datos y simplemente pasa los parámetros al componente genérico
const PrestadoresBurbujasChart = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericBurbujasChart
        ejeXLabel="Prestaciones por paciente"
        ejeYLabel="Meses por paciente"
        xField="prestaciones_paciente"
        yField="meses_paciente"
        title="Relación entre prestaciones y veces en el mes que fue el paciente"
      />
      <GenericBurbujasChart
        ejeXLabel="Prestaciones por paciente"
        ejeYLabel="Especialidades por mes"
        // xField="prestaciones_paciente"
        // yField="especialidades_mes"
        xField="meses_paciente"
        yField="pagado_paciente"
        title="Relación entre prestaciones y especialidades atendidas en el mes"
      />
    </DashboardLayout>
  );
};

export default PrestadoresBurbujasChart;
