import React, { useEffect, useState } from "react";
import { Bubble } from "react-chartjs-2";
import { useGlobalContext } from "context/AuthContextFile";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart, LinearScale, PointElement, BubbleController, Tooltip, Legend } from "chart.js"; // Importar las escalas y controladores
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Registrar el plugin de zoom y las escalas
Chart.register(zoomPlugin, LinearScale, PointElement, BubbleController, Tooltip, Legend);

const GenericBurbujasChart = ({
  ejeXLabel = "Prestaciones por paciente",
  ejeYLabel = "Meses por paciente",
  xField = "prestaciones_paciente", // Campo parametrizado para el eje X
  yField = "meses_paciente", // Campo parametrizado para el eje Y
  title = "titulo",
}) => {
  const { burbujasData } = useGlobalContext();
  const [provinceColors, setProvinceColors] = useState({}); // Almacenar los colores generados para cada provincia

  useEffect(() => {
    if (burbujasData && burbujasData.length > 0) {
      const colors = {};
      burbujasData.forEach((item) => {
        if (!colors[item.provincia]) {
          colors[item.provincia] = generatePastelColor(); // Generar color pastel si no existe
        }
      });
      setProvinceColors(colors); // Guardar los colores generados en el estado
    }
  }, [burbujasData]);

  const generatePastelColor = () => {
    const r = Math.floor(Math.random() * 100 + 155); // Más intenso, valores entre 155 y 255
    const g = Math.floor(Math.random() * 100 + 155);
    const b = Math.floor(Math.random() * 100 + 155);
    return `rgba(${r}, ${g}, ${b}, 0.8)`; // Mayor opacidad para hacer el color más intenso
  };

  const minFacturacion = Math.min(...burbujasData.map((item) => item.facturado));
  const maxFacturacion = Math.max(...burbujasData.map((item) => item.facturado));

  const minSize = 1;
  const maxSize = 30;

  const burbujasDataEscalada = burbujasData.map((item) => ({
    x: Number(item[xField]), // Usar la propiedad parametrizada para el eje X
    y: Number(item[yField]), // Usar la propiedad parametrizada para el eje Y
    r:
      minSize +
      ((Math.sqrt(item.facturado) - Math.sqrt(minFacturacion)) /
        (Math.sqrt(maxFacturacion) - Math.sqrt(minFacturacion))) *
        (maxSize - minSize),
  }));

  const chartData = {
    datasets: [
      {
        label: "Prestadores",
        data: burbujasDataEscalada,
        backgroundColor: burbujasData.map((item) => provinceColors[item.provincia]), // Usar los colores guardados en el estado
        borderColor: "rgba(54, 162, 235, 1)", // Borde de las burbujas
        borderWidth: 1,
      },
    ],
  };

  const minX = Math.min(...burbujasData.map((item) => Number(item[yField])));
  const maxX = Math.max(...burbujasData.map((item) => Number(item[yField]))) + 0.5;
  const minY = Math.min(...burbujasData.map((item) => Number(item[xField])));
  const maxY = Math.max(...burbujasData.map((item) => Number(item[xField])));

  const options = {
    plugins: {
      legend: {
        display: false, // Desactivar las leyendas de forma permanente
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const item = burbujasData[context.dataIndex];
            return [
              `Prestador: ${item.prestador}`,
              `Provincia: ${item.provincia}`,
              `Facturado: $${item.facturado}`,
              `Meses Pac.: ${item[yField]}`, // Usar la propiedad parametrizada
              `Prestaciones Pac.: ${item[xField]}`, // Usar la propiedad parametrizada
              `Prestaciones: ${item.prestaciones}`,
              `Pacientes: ${item.pacientes}`,
            ];
          },
        },
      },
      datalabels: {
        display: false, // Asegurarnos de que no aparezcan datalabels sobre las burbujas
      },
      zoom: {
        zoom: {
          drag: {
            enabled: true,
            borderColor: "rgba(255, 99, 132, 0.3)",
            borderWidth: 1,
            backgroundColor: "rgba(255, 99, 132, 0.1)",
          },
          mode: "xy",
        },
      },
    },
    onClick: (event, elements, chart) => {
      console.log(event);
      if (event.native.detail === 2) {
        chart.resetZoom();
      }
      if (event.native.detail === 1 && elements.length > 0) {
        const elementIndex = elements[0].index;
        const item = burbujasData[elementIndex];
        alert(
          `Prestador: ${item.prestador}\nProvincia: ${item.provincia}\nFacturado: $${item.facturado}\nMeses Pac.: ${item[yField]}` // Usar propiedad parametrizada
        );
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: ejeXLabel, // Etiqueta parametrizada
        },
        min: minY,
        max: maxY + 1,
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        title: {
          display: true,
          text: ejeYLabel, // Etiqueta parametrizada
        },
        min: minX,
        max: maxX,
        ticks: {
          stepSize: 1,
        },
      },
    },
    elements: {
      point: {
        radius: 5, // Mantener las burbujas visibles
        hoverRadius: 6, // Tamaño cuando se pasa el mouse, ajustado levemente
        pointStyle: "circle", // Usar el estilo de punto circular
        backgroundColor: "rgba(54, 162, 235, 0.5)", // Color de las burbujas
        borderWidth: 1, // Borde de la burbuja
      },
    },
  };

  return (
    <>
      {burbujasData?.length > 0 && (
        <Card sx={{ height: "100%", mt: 6 }}>
          <MDBox padding="1rem" sx={{ height: "100%" }}>
            <MDBox
              variant="gradient"
              bgColor={"indigolight"}
              borderRadius="lg"
              coloredShadow={"light"}
              py={2}
              pr={0.5}
              mt={-5}
              sx={{
                width: "100%",
                height: "25rem", // Ajustar altura al contenedor
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Bubble
                data={chartData}
                options={{
                  ...options,
                  responsive: true, // Asegurarse de que el gráfico sea responsive
                  maintainAspectRatio: false, // Permitir que el gráfico llene el contenedor
                }}
                style={{ width: "100%", height: "100%" }} // Hacer el gráfico responsive
              />
            </MDBox>
            <MDBox pt={3} pb={1} px={1}>
              <MDTypography variant="h6" textTransform="capitalize">
                {title}
              </MDTypography>
            </MDBox>
          </MDBox>
        </Card>
      )}
    </>
  );
};

export default GenericBurbujasChart;
