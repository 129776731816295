/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import react, { useState, useEffect, useContext } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight, Search } from "@mui/icons-material";
import ApiService from "ApiService/ApiService";
import moment from "moment";
import { useGlobalContext } from "context/AuthContextFile";
import { createFilterOptions } from "@mui/material/Autocomplete";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openError, setOpenError] = useState(false);

  const {
    setShowDashboard,
    setCalculatedData,
    selectedDate,
    zonales,
    interior,
    setSelectedDate,
    setZonales,
    setInterior,
    setMonthData,
    setAnualData,
    setEspecialidadData,
    setOdontologoData,
    setRedData,
    setProvinciasData,
    setBurbujasData,
    setClienteData,
    setGrupoClienteData,
    setMapData,
    filterOptions,
    selectedFilter,
    setSelectedFilter,
  } = useGlobalContext();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // Define el filtro personalizado aquí

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  const handlePrevMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setSelectedDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setSelectedDate(newDate);
  };

  const handleSearch = async () => {
    console.log(selectedFilter);
    if (!selectedFilter && !zonales && !interior) {
      setAlertMessage("Seleccione zonales, interior o ambos.");
      setOpenAlert(true);
      return;
    }
    setMonthData([]);
    setEspecialidadData([]);
    setOdontologoData([]);
    setRedData([]);
    setProvinciasData([]);
    setBurbujasData([]);
    setClienteData([]);
    setGrupoClienteData([]);
    setMapData([]);
    setAnualData([]);
    setCalculatedData(null);
    setShowDashboard(false);
    setLoading(true);
    setError(null);
    setOpenError(false);
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1;
    const startDate = new Date(year, month - 1, 1); // Mes - 1 porque el constructor de Date usa índice base 0
    const endDate = new Date(year, month, 0);
    const startDateString = moment(startDate).format("YYYY-MM-DD");
    const endDateString = moment(endDate).format("YYYY-MM-DD");
    const startPreviousMonthDate = new Date(year, month - 2, 1); // Restar 1 mes, manejando cambio de año
    const startPreviousMonthString = moment(startPreviousMonthDate).format("YYYY-MM-DD");
    const startAnualDate = new Date(year, month - 13, 1); // Restamos 13 meses
    const startAnualDateString = moment(startAnualDate).format("YYYY-MM-DD");
    console.log("Year:", year);
    console.log("Month:", month);
    console.log("Fecha ajustada:", startPreviousMonthDate);
    let grupoLiquidacion = null;
    if (interior && !zonales) {
      grupoLiquidacion = "interior";
    } else if (zonales && !interior) {
      grupoLiquidacion = "zonales";
    }
    console.log("Searching with filters:", {
      startPreviousMonthString,
      startAnualDateString,
      startDateString,
      endDateString,
      zonales,
      interior,
    });
    try {
      const params = {
        start_anual_date: startAnualDateString,
        start_previous_date: startPreviousMonthString,
        start_date: startDateString,
        end_date: endDateString,
        minPrestaciones: 90,
        minPacientes: 30,
      };
      if (grupoLiquidacion !== null) {
        params.grupo_liquidacion = grupoLiquidacion; // Agregar solo si no es null
      }
      if (selectedFilter !== null) {
        if (selectedFilter.tabla === "clientes") {
          params.prepagaId = selectedFilter.id;
        } else if (selectedFilter.tabla === "grupo_clientes") {
          params.grupoPrepagaId = selectedFilter.id;
        } else if (selectedFilter.tabla === "redes") {
          params.redId = selectedFilter.id;
        } else if (selectedFilter.tabla === "odontologos") {
          params.odontologoId = selectedFilter.id;
        }
      }
      console.log(params);
      const data = await ApiService.apiGet("estadisticas", null, "panel/initialdata", params);
      console.log(data);
      if (data.error === false) {
        setMonthData(data.dataMensual);
        setEspecialidadData(data.dataEspecialidad);
        setOdontologoData(data.dataOdontologo);
        setRedData(data.dataRed);
        setProvinciasData(data.dataProvincias);
        setBurbujasData(data.dataBurbujas);
        setClienteData(data.dataCliente);
        setGrupoClienteData(data.dataGrupoCliente);
        setMapData(data.dataMap);
        setAnualData(data.dataAnual);
      } else {
        alert("Ver error");
      }
    } catch (err) {
      // alert(err.message);
      console.log(err);
      // Capturamos el error lanzado y mostramos el mensaje
      setError(err.message); // El error lanzado es capturado aquí
      setOpenError(true);
    } finally {
      setLoading(false);
    }
  };

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      //    return "colorValue";
      return "#1A73E8";
    },
  });

  const customFilterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.codigo} ${option.razon} ${option.fantasia}`,
  });

  const handleCloseAlert = () => {
    setAlertMessage(null);
    setOpenAlert(false);
  };

  const capitalizeWords = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Grid container spacing={1} alignItems="center">
      {/* Columna 1: Botón de menú y campo de búsqueda */}
      <Grid item xs={12} sm={6} md={4}>
        <Box display="flex" alignItems="start">
          <IconButton
            size="small"
            disableRipple
            color="inherit"
            sx={navbarMobileMenu}
            onClick={handleMiniSidenav}
          >
            <Icon sx={iconsStyle} fontSize="large">
              {miniSidenav ? "menu" : "menu"}
            </Icon>
          </IconButton>
          <Autocomplete
            fullWidth
            options={filterOptions}
            groupBy={(option) => option.tabla}
            getOptionLabel={(option) =>
              `${capitalizeWords(option.codigo)} - ${capitalizeWords(
                option.razon
              )} - ${capitalizeWords(option.fantasia)}`
            }
            filterOptions={customFilterOptions}
            value={selectedFilter}
            onChange={(event, newValue) => setSelectedFilter(newValue)}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.875rem",
                  }}
                >
                  {`${capitalizeWords(option.codigo)} - ${capitalizeWords(
                    option.razon
                  )} - ${capitalizeWords(option.fantasia)}`}
                </Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Selecciona una opción" variant="outlined" />
            )}
          />
        </Box>
      </Grid>

      {/* Columna 2: Selector de mes y año */}
      <Grid item xs={12} sm={6} md={5}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button size="large" onClick={handlePrevMonth}>
            <KeyboardArrowLeft sx={{ fontSize: "2rem" }} />
          </Button>

          <Typography
            variant="body2"
            sx={{
              margin: "0 16px",
              width: "150px",
              textAlign: "center",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {`${selectedDate.toLocaleString("default", {
              month: "long",
            })} ${selectedDate.getFullYear()}`}
          </Typography>

          <Button size="large" onClick={handleNextMonth}>
            <KeyboardArrowRight sx={{ fontSize: "2rem" }} />
          </Button>
        </Box>
      </Grid>

      {/* Columna 3: Checkboxes y botón de búsqueda */}
      <Grid item xs={12} md={3}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={zonales}
                onChange={(e) => setZonales(e.target.checked)}
                color="primary"
              />
            }
            label="Zonales"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={interior}
                onChange={(e) => setInterior(e.target.checked)}
                color="primary"
              />
            }
            label="Interior"
          />
          <Button
            onClick={handleSearch}
            size="medium"
            variant="outlined"
            color="info"
            sx={{
              marginLeft: "16px",
              minWidth: "auto",
              display: "flex",
              alignItems: "center",
              color: "info.main", // Aseguramos que el texto e icono usen el color primario
              borderColor: "info.main", // Aseguramos que el borde también sea del color primario
            }}
            startIcon={<Search sx={{ fontSize: "2rem", color: "info.main" }} />} // Aseguramos el color del ícono
          >
            Buscar
          </Button>
        </Box>
      </Grid>
      {/* Snackbar para mostrar la alerta */}
      <Snackbar
        open={openAlert && alertMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        variant="filled"
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {loading && (
        <Box sx={{ width: "95%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size="3rem" />
        </Box>
      )}
    </Grid>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: true,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
