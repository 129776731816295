import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useGlobalContext } from "context/AuthContextFile"; // Importa el contexto de autenticación
import PropTypes from "prop-types";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading, recheckAuth } = useGlobalContext(); // recheckAuth to force recheck auth status
  const [shouldNavigate, setShouldNavigate] = useState(null);

  useEffect(() => {
    if (!loading) {
      if (isAuthenticated) {
        setShouldNavigate(children);
      } else {
        recheckAuth(); // Trigger rechecking of authentication after clearing storage
        setShouldNavigate(<Navigate to="/sign-in" />);
      }
    }
  }, [loading, isAuthenticated, children, recheckAuth]);

  if (loading) {
    // Show loading indicator
    return <div>Loading...</div>;
  }

  return shouldNavigate;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired, // Ensure 'children' prop is passed and is a valid React node
};

export default ProtectedRoute;
