import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"; // Yup for validation
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import { Card, Box, IconButton, TextField, Avatar, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import LoadingButton from "@mui/lab/LoadingButton";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import AvatarAzul from "assets/images/AvatarAzul96.png";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ApiService from "ApiService/ApiService";
import Crypto from "components/Crypto/Crypto";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/AuthContextFile";
import { Alert } from "@mui/material";

function Basic() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initialEmail, setInitialEmail] = useState("");
  const navigate = useNavigate();
  const { recheckAuth, resetState } = useGlobalContext();
  const [openError, setOpenError] = useState(false);

  const { setFilterOptions } = useGlobalContext();

  useEffect(() => {
    const savedEmail = Crypto.decryptLocalStorage("user_email");
    localStorage.clear(); // Limpiar todo el localStorage
    resetState();
    if (savedEmail) {
      setInitialEmail(savedEmail);
      Crypto.encryptLocalStorage("user_email", savedEmail);
    }
  }, []); // Empty dependency array ensures this runs once on component mount

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = (event, reason) => {
    setOpenError(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Email incorrecto").required("Ingrese el email"),
    password: Yup.string()
      .min(4, "La clave debe tener al menos 4 caracteres")
      .required("Ingrese la clave"),
  });

  const loginUser = async (values) => {
    setLoading(true);
    setError(null);
    setOpenError(false);
    try {
      // Llamamos a la API
      const data = await ApiService.login(values);
      // Si no se encuentran clientes, lanzamos un error personalizado
      if (data.error === false) {
        Crypto.encryptLocalStorage("jwtcr", data.token.original.access_token);
        Crypto.encryptLocalStorage("user_email", data.token.original.user.email);
        Crypto.encryptLocalStorage("user_id", data.token.original.user.userid_capitas);
        Crypto.encryptLocalStorage("user_api_id", data.token.original.user.id);
        Crypto.encryptLocalStorage("user_name", data.token.original.user.name);

        // Explicitly recheck the authentication status
        recheckAuth();
        searchFilterOptions();
      } else {
        alert("Ver error");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      // Capturamos el error lanzado y mostramos el mensaje
      setError(err.message); // El error lanzado es capturado aquí
      setOpenError(true);
    } finally {
    }
  };

  const searchFilterOptions = async () => {
    setLoading(true);
    setError(null);
    setOpenError(false);
    try {
      // Llamamos a la API
      const data = await ApiService.apiGet("estadisticas", null, "panel/filteroptions", null);
      // Si no se encuentran clientes, lanzamos un error personalizado
      if (data.error === false) {
        console.log(data);
        setFilterOptions(data.data);
        navigate("/dashboard");
      } else {
        alert("Ver error");
      }
    } catch (err) {
      console.log(err);
      // Capturamos el error lanzado y mostramos el mensaje
      setError(err.message); // El error lanzado es capturado aquí
      setOpenError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
          display="flex" // Usamos flexbox
          flexDirection="column" // Coloca los elementos en una columna
          alignItems="center" // Centra los elementos horizontalmente
        >
          <Avatar
            alt="Logo"
            src={AvatarAzul}
            sx={{
              //        display: { xs: "flex", lg: "none" },
              width: 96,
              height: 96,
              justifyContent: "center",
              mt: -1,
              mb: 2.5,
            }}
          />
          <Typography
            component="h1"
            variant="h5"
            sx={{
              mb: 4,
              color: "white !important",
            }}
          >
            Iniciar sesión
          </Typography>
        </MDBox>
        <MDBox pt={4} pb={3} px={0}>
          <Formik
            enableReinitialize={true}
            initialValues={{ email: initialEmail, password: "" }} // Use empty strings instead of null
            validationSchema={validationSchema}
            onSubmit={(values) => {
              loginUser(values);
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit, touched, errors }) => (
              <Form onSubmit={handleSubmit} noValidate>
                {" "}
                {/* Include Formik's Form element */}
                <MDBox pt={4} pb={3} px={3}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    size="medium"
                    type="email"
                    value={values.email} // Connect with Formik's value
                    onChange={handleChange} // Connect with Formik's handleChange
                    onBlur={handleBlur} // Ensure to trigger the "touched" status
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    error={touched.email && Boolean(errors.email)} // Display error state
                    helperText={touched.email && errors.email ? errors.email : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      mb: 4, // Add margin-bottom for spacing
                    }}
                  />
                  <TextField
                    id="password"
                    name="password"
                    label="Clave"
                    size="medium"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password ? errors.password : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <KeyIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box
                    sx={{
                      mt: 5,
                    }}
                  >
                    {openError && (
                      <Alert
                        severity="error"
                        variant="filled"
                        onClose={() => setOpenError(false)}
                        sx={{ mb: 2 }}
                      >
                        {error}
                      </Alert>
                    )}
                    <LoadingButton
                      size="medium"
                      type="submit"
                      fullWidth
                      variant="contained"
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<LoginIcon />}
                      sx={{
                        backgroundColor: "primary", // Background color
                        color: "white !important", // Force white text color
                        ":hover": {
                          bgcolor: "#64748b", // Darker background on hover
                          color: "white !important", // Ensure text stays white on hover
                        },
                        textTransform: "capitalize", // Optional: Remove default uppercase style
                        height: 48, // Button height
                      }}
                    >
                      <span>Ingresar</span>
                    </LoadingButton>
                  </Box>
                </MDBox>
              </Form>
            )}
          </Formik>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Basic;
