import axios from "axios";
import crypto from "components/Crypto/Crypto";

class ApiService {
  constructor() {
    // Lógica para configurar la URL base según el hostname
    let REACT_APP_WEBAPI_URL = "";
    if (window.location.hostname.indexOf("clinicasapp.consulmed.com.ar") >= 0) {
      REACT_APP_WEBAPI_URL = "https://api.consulmed.com.ar/api/";
    } else {
      REACT_APP_WEBAPI_URL = "https://consulmedapi-test-i2aufchu7q-ue.a.run.app/api/";
    }

    // Crear instancia de axios con baseURL dinámico y headers para Capitas
    this.http = axios.create({
      baseURL: REACT_APP_WEBAPI_URL,
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
        "X-App-Origin": "capitas", // Encabezado específico para Capitas
      },
    });

    this.http.interceptors.request.use(
      (config) => {
        const token = crypto.decryptLocalStorage("jwtcr");
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async login(data = {}) {
    try {
      // Crear una nueva instancia de axios sin el header de Authorization
      let REACT_APP_WEBAPI_URL = "";
      if (window.location.hostname.indexOf("clinicasapp.consulmed.com.ar") >= 0) {
        REACT_APP_WEBAPI_URL = "https://api.consulmed.com.ar/api/";
      } else {
        REACT_APP_WEBAPI_URL = "https://consulmedapi-test-i2aufchu7q-ue.a.run.app/api/";
      }

      const loginHttp = axios.create({
        baseURL: REACT_APP_WEBAPI_URL,
        headers: {
          "Content-type": "application/json",
          "X-App-Origin": "capitas", // Encabezado específico para Capitas
        },
      });

      // Realizar la solicitud POST para login
      const result = await loginHttp.post("/login", data);

      console.log(result);

      // Manejar códigos de estado no exitosos
      if (result.status !== 200) {
        throw new Error(`HTTP error! status: ${result.status}`);
      }

      // Verificar si hay un error en la respuesta de la API
      if (result.data.error) {
        throw new Error(result.data.errormessage || "Unknown API error");
      }

      // Retornar los datos de la respuesta
      return result.data;
    } catch (err) {
      throw err; // Volver a lanzar el error para manejarlo en el componente que llame este método
    }
  }

  async apiGet(url, modeloId = null, urlVista = null, params = null) {
    try {
      let getUrl = url;

      // Check and append modeloId if present
      if (modeloId !== null) {
        getUrl += "/" + modeloId.toString().trim();
      }

      // Check and append urlVista if present
      if (urlVista !== null) {
        getUrl += "/" + urlVista.toString().trim();
      }

      // Build query string with URLSearchParams

      const token = crypto.decryptLocalStorage("jwtcr");

      console.log(token);

      // Crear una instancia de URLSearchParams
      const queryParams = new URLSearchParams();
      queryParams.append("api_url", getUrl); // Añadir el api_url

      // Agregar los parámetros al objeto URLSearchParams
      if (params) {
        for (const [key, value] of Object.entries(params)) {
          if (value !== null && value !== undefined) {
            queryParams.append(key, value);
          }
        }
      }
      console.log(queryParams.toString());
      // Hacer la solicitud GET
      const result = await this.http.get(`/apigetjson?${queryParams.toString()}`).catch((error) => {
        console.error("Error in API call:", error);
        throw error; // Lanza el error nuevamente si necesitas manejarlo en otro lugar
      });

      console.log("Result: " + result);

      // Handle non-200 status codes
      if (result.status !== 200) {
        throw new Error(`HTTP error! status: ${result.status}`);
      }

      // Check if there's an error in the API response
      if (result.data.error) {
        throw new Error(result.data.errormessage || "Unknown API error");
      }

      // Return the response data
      return result.data;
    } catch (err) {
      if (err.status === 401) {
        alert("Error 401 out !" + err.message);
        alert(err.response.data.errormessage);
      }
      throw err; // Re-throw the error for handling in the calling component
    }
  }

  async apiPost(url, modeloId = null, urlVista = null, data = {}) {
    try {
      let postUrl = url;

      // Check and append modeloId if present
      if (modeloId !== null) {
        postUrl += "/" + modeloId.toString().trim();
      }

      // Check and append urlVista if present
      if (urlVista !== null) {
        postUrl += "/" + urlVista.toString().trim();
      }

      // Perform the POST request
      const result = await this.http.post("/apipost", {
        api_url: postUrl,
        ...data, // Spread the data object into the request body
      });

      // Handle non-200 status codes
      if (result.status !== 200) {
        throw new Error(`HTTP error! status: ${result.status}`);
      }

      // Check if there's an error in the API response
      if (result.data.error) {
        throw new Error(result.data.errormessage || "Unknown API error");
      }

      // Return the response data
      return result.data;
    } catch (err) {
      throw err; // Re-throw the error for handling in the calling component
    }
  }

  // Método para PUT
  async apiPut(url, modeloId = null, urlVista = null, data = {}) {
    try {
      let putUrl = url;

      // Check and append modeloId if present
      if (modeloId !== null) {
        putUrl += "/" + modeloId.toString().trim();
      }

      // Check and append urlVista if present
      if (urlVista !== null) {
        putUrl += "/" + urlVista.toString().trim();
      }

      const result = await this.http.put("/apiput", {
        api_url: putUrl,
        ...data,
      });

      // Handle non-200 status codes
      if (result.status !== 200) {
        throw new Error(`HTTP error! status: ${result.status}`);
      }

      // Check if there's an error in the API response
      if (result.data.error) {
        throw new Error(result.data.errormessage || "Unknown API error");
      }

      // Return the response data
      return result.data;
    } catch (err) {
      throw err; // Re-throw the error for handling in the calling component
    }
  }

  // Método para DELETE
  async apiDelete(url, modeloId) {
    try {
      let deleteUrl = url;
      deleteUrl += "/" + modeloId.toString().trim();

      const queryParams = new URLSearchParams();
      queryParams.append("api_url", deleteUrl);

      const result = await this.http.delete(`/apidelete?${queryParams.toString()}`);

      // Handle non-200 status codes
      if (result.status !== 200) {
        throw new Error(`HTTP error! status: ${result.status}`);
      }

      // Check if there's an error in the API response
      if (result.data.error) {
        throw new Error(result.data.errormessage || "Unknown API error");
      }

      // Return the response data
      return result.data;
    } catch (err) {
      throw err; // Re-throw the error for handling in the calling component
    }
  }
}

export default new ApiService();
